import React from 'react'

import HowDoesItWork from '../content/HowDoesItWork'
import PricesAndContactBtns from '../quick-buttons/PricesAndContactBtns'

class GalleryPage extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="page-title">
          <h2>¿Cómo funciona?</h2>
        </div>
        <HowDoesItWork showTitle={ false } />
        <PricesAndContactBtns />
      </React.Fragment>
    )
  }
}

export default GalleryPage
