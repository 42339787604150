import React from 'react'
import Contact from '../content/Contact'
import Map from '../content/Map'

class ContactPage extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="page-title">
          <h2>Contacto</h2>
        </div>
        <Contact />
        <Map />
      </React.Fragment>
    )
  }
}

export default ContactPage
