import React from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'

class ScrollToTop extends React.Component {
  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
      // TODO: Improve
      const $ = require('jquery')
      $('header').removeClass('nav-up').addClass('nav-down')
    }
  }

  render () {
    return this.props.children
  }
}

ScrollToTop.propTypes = {
  location: PropTypes.any.isRequired,
  children: PropTypes.element
}

export default withRouter(ScrollToTop)
