import React from 'react'
import { Link } from 'react-router-dom'

class NotFoundPage extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="section service-section">
          <div className="container-fluid">
            {/*
              <div className="col-md-12 ">
                <span className="section-sub-title pull-right">Que&nbsp;
                  <span>ofrecemos</span> a nuestros clientes.</span>
                </div>
              */}
            <div className="container section-header">
              <h1 className="title">Página no encontrada</h1>
              <p className="sub-title">
                Parece que la página que intentas acceder ya no existe.
              </p>
            </div>

            <div className="quick-buttons">
              <Link to="/" className="btn btn-primary btn-lg">
                <i className="fa fa-home" aria-hidden="true"></i> Volver a la página principal
              </Link>
              <Link to="/contacto" className="btn btn-default btn-lg">
                <i className="fa fa-envelope-o" aria-hidden="true"></i> Contacto
              </Link>
            </div>

          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default NotFoundPage
