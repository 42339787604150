/* eslint no-undef: "error" */

import React from 'react'
import $ from 'jquery'
import loadScript from '../../util/loadScript'

let googleMapsInjected = false

const COORDINATES_MAP_CENTER = [43.3617, -8.4150]
const COORDINATES_FESTERRA = [43.3611646, -8.416027900000017]
const MARKET_TOOLTIP = `
<div class="map-tooltip">
  <p>
    <br />
    <strong>Festerra</strong>
  </p>            
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6 col-xs-12">
        <p>
          En la avenida de finisterre, justo despues de pasar el cruce de
          la Ronda de Nelle subiendo, a mano derecha.
        </p>
        <p>
          <a href="https://www.google.com/maps/search/?api=1&query=Avenida%20de%20Finisterre,%20196.%2015010,%20A%20Coruña" target="_blank" rel="noopener noreferrer">
            Avenida de Finisterre, 196 <br/>
            15010, A Coruña
          </a>
        </p>
      </div>
      <div class="col-sm-6 hidden-xs">
        <a href="https://www.google.com/maps/search/?api=1&query=Avenida%20de%20Finisterre,%20196.%2015010,%20A%20Coruña" target="_blank" rel="noopener noreferrer">
          <img height="100px" src="/images/festerra-google-maps.png" class="img-rounded">
        </a>
      </div>
    </div>
  </div>
</div>
`

function loadGoogleMapsLib ({
  key,
  language,
  region,
  callbackFunctionName
}) {
  loadScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${key}&language=${language}&region=${region}&callback=${callbackFunctionName}`
  })
  googleMapsInjected = true
}

function drawMap () {
  // console.log('Draw map')
  let map
  const google = window.google
  var infowindow = new google.maps.InfoWindow()
  $('.map')
    .gmap3({
      zoom: 17,
      center: COORDINATES_MAP_CENTER,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapTypeControl: true,
      mapTypeControlOptions: {
        style: google.maps.MapTypeControlStyle.DROPDOWN_MENU
      },
      navigationControl: true,
      scrollwheel: false,
      streetViewControl: true
    })
    .marker(function (_map) {
      /*
      var icon = {
        url: '/images/festerra-marker.png',
        scaledSize: new google.maps.Size(107.4, 10.5), // scaled size
        origin: new google.maps.Point(0, 0), // origin
        anchor: new google.maps.Point(107.4, 10.5) // anchor
      }
      */
      map = _map
      return {
        position: COORDINATES_FESTERRA
        // icon
      }
    })
    .then(marker => {
      // Add go to Google maps btn
      var gotoMapButton = document.createElement('button')
      gotoMapButton.setAttribute('style', 'margin: 1em;')
      gotoMapButton.setAttribute('class', 'btn btn-default btn-sm')
      gotoMapButton.innerHTML = `<i class="fa fa-map-marker"></i> Abrir en Google Maps`
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(gotoMapButton)
      google.maps.event.addDomListener(gotoMapButton, 'click', function () {
        window.open('https://www.google.com/maps/search/?api=1&query=Avenida%20de%20Finisterre,%20196.%2015010,%20A%20Coruña')
      })

      // Show tooltip event listener
      google.maps.event.addListener(marker, 'click', function () {
        infowindow.setContent(MARKET_TOOLTIP)
        infowindow.open(map, this)
      })

      // Hide tooltip event listener
      google.maps.event.addListener(map, 'click', () => {
        infowindow.close()
        map.setOptions({
          scrollwheel: true
        })
      })
    })
}

function onGoogleMapsReady () {
  // console.log('onGoogleMapsReady: load gmap3')
  loadScript({
    src: 'https://cdn.jsdelivr.net/gmap3/7.2.0/gmap3.min.js',
    onload: drawMap
  })
}

class Map extends React.Component {
  componentDidMount () {
    if (!googleMapsInjected) {
      window.onGoogleMapsReady = onGoogleMapsReady
      loadGoogleMapsLib({
        key: 'AIzaSyDQOMHquz3F2A8xAe_dUd4cyAhOVpgaOt4',
        language: 'es',
        region: 'ES',
        callbackFunctionName: 'onGoogleMapsReady'
      })
    } else {
      drawMap()
    }
  }

  render () {
    return (
      <div className="map-group">
        <div className="responsive-visit-us hidden-lg hidden-md hidden-lg">
          <i className="fa fa-car"></i>
          <h6>VISÍTANOS</h6>
          <p>
            <a href="https://www.google.com/maps/search/?api=1&query=Avenida%20de%20Finisterre,%20196.%2015010,%20A%20Coruña"
              target="_blank" rel="noopener noreferrer">
              Avenida de Finisterre, 196
              <br /> 15010, A Coruña
            </a>
          </p>
        </div>
        <div className="map"></div>
      </div>
    )
  }
}

export default Map
