import React from 'react'
import validationUtils from '../utils/validationUtils'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import api from '../../api'

function getDefaultState (isReservationForm) {
  let state = {
    name: '',
    email: '',
    phone: '',
    message: '',
    formAlreadySent: false,
    showSuccessMessage: false,
    showErrorMessage: false,
    sendingMail: false,
    errors: {
      name: false,
      phone: false,
      email: false,
      emailFormat: false,
      message: false
    }
  }

  if (isReservationForm) {
    state = {
      ...state,
      dateDetails: '',
      errors: {
        ...state.errors,
        dateDetails: false
      }
    }
  }

  return state
}

class ContactForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = getDefaultState(props.isReservationForm)

    this.textareaInput = React.createRef()
    this.dateDetailsInput = React.createRef()
    this.nameInput = React.createRef()

    this.handleSubmit = this.handleSubmit.bind(this)
    this.validate = this.validate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.resetState = this.resetState.bind(this)
  }

  componentDidMount () {
    if (this.props.focusOnRender) {
      this.nameInput.current.focus()
    }
  }

  handleSubmit (event) {
    event.preventDefault()

    // Validate
    this.validate(() => {
      const hasErrors = this.hasErrors()

      if (!hasErrors) {
        const {
          name,
          email,
          phone,
          message,
          dateDetails
        } = this.state
        this.setState({
          sendingMail: true
        })
        let mailPromise, formName
        if (this.props.isReservationForm) {
          formName = 'Reservation form'
          mailPromise = api.requestReservation({
            name,
            email,
            phone,
            message,
            dateDetails
          })
        } else {
          formName = 'Contact form'
          mailPromise = api.contact({
            name,
            email,
            phone,
            message
          })
        }

        // Send to Google analytics
        window.ga('send', {
          hitType: 'event',
          eventCategory: formName,
          eventAction: 'sendMail'
        })
        mailPromise
          .then(() => {
            this.resetState({
              showSuccessMessage: true
            })
          })
          .catch(error => {
            console.error(error)
            this.setState({
              showErrorMessage: true,
              sendingMail: false
            })
            window.ga('send', {
              hitType: 'exception',
              exDescription: error.message,
              exFatal: false
            })
          })
      }
    })
  }

  resetState (newState) {
    const state = getDefaultState(this.props.isReservationForm)
    this.setState({
      ...state,
      ...newState
    })
  }

  handleInputChange (event) {
    const target = event.target
    const value = target.type === 'checkbox' ? target.checked : target.value
    const name = target.name

    this.setState({
      [name]: value
    }, () => {
      if (this.state.formAlreadySent) {
        this.validate()
      }
    })
  }

  validate (callback) {
    const {
      name,
      email,
      phone,
      message,
      dateDetails
    } = this.state

    let errors = {
      name: !name,
      email: !email,
      emailFormat: !!email && !validationUtils.isValidEmail(email),
      phone: !phone,
      message: !message
    }

    if (this.props.isReservationForm) {
      errors = {
        ...errors,
        eventDate: !dateDetails
      }
    }

    this.setState({
      formAlreadySent: true,
      errors
    }, callback)
  }

  hasErrors () {
    const errors = this.state.errors
    return Object.keys(errors).some(field => errors[field])
  }

  render () {
    let content
    if (this.state.showSuccessMessage) {
      content = this.renderSuccess()
    } else {
      const form = this.renderForm()
      if (this.state.showErrorMessage) {
        content = [
          this.renderError(),
          form
        ]
      } else {
        content = form
      }
    }

    return (
      <div className="contact-form">
        { content }
      </div>
    )
  }

  renderForm () {
    const hasErrors = this.hasErrors()
    let emailErrorMsg
    if (this.state.errors.email) {
      emailErrorMsg = 'Indique el e-mail de contacto'
    } else if (this.state.errors.emailFormat) {
      emailErrorMsg = 'Revise el correo electrónico, el formato no es válido'
    } else {
      emailErrorMsg = null
    }

    let timeSelection
    if (this.props.isReservationForm) {
      timeSelection = this.renderTimeSelection()
    }

    return (
      <form key="form" onSubmit={ this.handleSubmit } className="form-common">
        <div className="row">
          <div className="col-sm-12">
            <div className={ 'form-group' + (this.state.errors.name ? ' has-error' : '') }>
              <input
                value={ this.state.name }
                name="name"
                onChange={ this.handleInputChange }
                type="text"
                className="form-control"
                placeholder="Tu nombre"
                ref={ this.nameInput }
              />
              <span className="help-block">
                Indique su nombre
              </span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className={ 'form-group' + (this.state.errors.phone ? ' has-error' : '') }>
              <input
                value={ this.state.phone }
                name="phone"
                onChange={ this.handleInputChange }
                type="text"
                className="form-control"
                placeholder="Telefono"
              />
              <span className="help-block">
                Indique su telefono de contacto
              </span>
            </div>
          </div>
          <div className="col-sm-6">
            <div className={ 'form-group' + (emailErrorMsg ? ' has-error' : '') }>
              <input
                value={ this.state.email }
                name="email"
                onChange={ this.handleInputChange }
                type="text"
                className="form-control"
                placeholder="Email"
              />
              <span className="help-block">
                { emailErrorMsg || '&nbsp;' }
              </span>
            </div>
          </div>

          { timeSelection }

          <div
            className="col-sm-12"
            onClick={ () => this.textareaInput.current.focus() }>
            <div className={ 'form-group' + (this.state.errors.message ? ' has-error' : '') }>
              <label
                className="control-label"
                htmlFor="message">Tu mensaje:</label>
              <textarea
                value={ this.state.message }
                name="message"
                className="message"
                onChange={ this.handleInputChange }
                ref={ this.textareaInput }
                cols="10"
                rows="2">
              </textarea>
              <span className="help-block">
                Por favor, incluya el mensaje
              </span>
            </div>
          </div>

          <div className="col-sm-12">
            <p className="btn-area">
              { this.state.sendingMail &&
                <button
                  disabled={ true }
                  className="btn btn-primary btn-lg loading">
                  Enviando mensaje&nbsp;
                  <span>.</span>
                  <span>.</span>
                  <span>.</span>
                </button>
              }
              { !this.state.sendingMail &&
                <button
                  disabled={ hasErrors }
                  type="submit"
                  className="btn btn-primary btn-lg">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i> Enviar mensaje
                </button>
              }
            </p>
          </div>
        </div>
      </form>
    )
  }

  renderSuccess () {
    return (
      <React.Fragment>
        <div className="alert alert-success" role="alert">
          <button
            type="button"
            onClick={ () => this.setState({ showSuccessMessage: false }) }
            className="close"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <p><strong>Muchas gracias por el mensaje!</strong></p>
          <p>Nos pondremos en contacto tan pronto nos sea posible.</p>
        </div>

        <div className="alert alert-warning" role="alert">
          <button
            type="button"
            onClick={ () => this.setState({ showSuccessMessage: false }) }
            className="close"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <p><strong>¡IMPORTANTE!</strong></p>
          <p>
            Si ves que tardamos un poco en contestar, por favor revisa la
            bandeja de <strong>SPAM</strong> o vuelve a ponerte en contacto con nosotros.
          </p>
        </div>

        <div className="quick-buttons">
          <Link
            to="/"
            className="btn btn-primary btn-lg">
            <i className="fa fa-home" aria-hidden="true"></i> Ir a la página principal
          </Link>
        </div>
      </React.Fragment>
    )
  }

  renderError () {
    return (
      <div key="alert-error" className="alert alert-danger" role="alert">
        <button
          type="button"
          onClick={ () => this.setState({ showErrorMessage: false }) }
          className="close"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <p>Ha ocurrido un error interno</p>
        <p>
          Por favor, reintente el envío y si el error persiste póngase en
          contacto a traves del correo&nbsp;
          <a href="mailto:reservas@festerra.com">reservas@festerra.com</a>
        </p>
      </div>
    )
  }

  renderTimeSelection () {
    return (
      <div
        className="col-sm-12"
        onClick={ () => this.dateDetailsInput.current.focus() }>
        <div className={ 'form-group' + (this.state.errors.dateDetails ? ' has-error' : '') }>
          <label
            className="control-label"
            htmlFor="dateDetails">Para cuando querías tu reserva:</label>
          <textarea
            value={ this.state.dateDetails }
            className="dateDetails"
            name="dateDetails"
            onChange={ this.handleInputChange }
            ref={ this.dateDetailsInput }
            cols="10"
            rows="2">
          </textarea>
          <span className="help-block">
            Por favor, indique para cuando quieres hacer tu reserva
          </span>
        </div>
      </div>
    )
  }
}

ContactForm.propTypes = {
  isReservationForm: PropTypes.bool,
  focusOnRender: PropTypes.bool
}

export default ContactForm
