import React from 'react'

class ReturnPolicy extends React.Component {
  render () {
    return (
      <div id="return-policy" className="section return-policy-section m-tb120">
        <div className="container">
          <div className="container section-header">
            <h1 className="title">Política devoluciones</h1>
          </div>
          <div className="row">
            <div className="col-md-7 col-md-offset-2 col-sm-12">
              <ul>
                <li>Más de 30 días de antelación sobre la fecha reservada: Sin penalización, se devuelve el pago completo.</li>
                <li>Entre 7-30 días antes del día reservado: Penalización de 50 euros, te devolvemos el resto del importe pagado.</li>
                <li>Con menos de 7 días antes de la fecha reservada: Penalización 100% de la reserva, es decir no se reembolsará
                  ninguna cantidad.</li>
                <li>La edad mínima de la persona que reserva la sala será de 18 años.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ReturnPolicy
