import React from 'react'

function trackClickOn (event, componentName) {
  window.ga('send', {
    hitType: 'event',
    eventCategory: componentName,
    eventAction: 'click'
    // eventLabel: event.target.href
  })
}

const SocialIcons = () => (
  <div className="social-icons">
    <a href="https://www.facebook.com/festerra/"
      onClick={ e => trackClickOn(e, 'Header: facebook btn') }
      target="_blank" rel="noopener noreferrer">
      <i className="fa fa-facebook"></i>
    </a>
    <a href="https://www.instagram.com/festerra_/"
      onClick={ e => trackClickOn(e, 'Header: instagram btn') }
      target="_blank" rel="noopener noreferrer">
      <i className="fa fa-instagram"></i>
    </a>
  </div>
)

export default SocialIcons
