import React from 'react'

import WhatDoWeOfferComponent from '../content/WhatDoWeOffer'
import OurFacilities from '../content/OurFacilities'
import HowDoesItWork from '../content/HowDoesItWork'
import PricesAndContactBtns from '../quick-buttons/PricesAndContactBtns'

class WhatDoWeOffer extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="page-title">
          <h2>Qué ofrecemos</h2>
        </div>
        <PricesAndContactBtns />
        <WhatDoWeOfferComponent />
        <OurFacilities />
        <HowDoesItWork />
        <PricesAndContactBtns />
      </React.Fragment>
    )
  }
}

export default WhatDoWeOffer
