import React from 'react'
/*
import supermarketImg from '../../images/nearby_services/supermarket_small.jpg'
import parkingImg from '../../images/nearby_services/parking_small.jpg'
import busImg from '../../images/nearby_services/bus_small.jpg'
*/
import BusLinesInfo from './BusLinesInfo'

class NearbyServices extends React.Component {
  render () {
    return (
      <div id="service" className="section service-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <span className="section-sub-title">
              Servicios&nbsp;
              <span>próximos</span>
            </span>
          </div>

          <div className="row">
            <div className="col-md-9 col-md-offset-2 col-sm-12">
              <div className="row">
                <div className="col-sm-4">
                  <article className="service-single">
                    <div className="service-icon">
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                    </div>
                    <h2 className="sub-title">Supermercados</h2>
                    <ul>
                      <li>Gadis a 160 metros (
                        <a href="https://goo.gl/maps/cwt5umC6UER2"
                          target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-map-marker"></i>
                          Calle Andrés Gaos
                        </a>)
                      </li>
                      <li>Familia a 260 metros (
                        <a href="https://goo.gl/maps/bSGN61bUm7z"
                          target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-map-marker"></i>
                          Calle Alcalde Lens
                        </a>)</li>
                      <li>Mercadona 500 metros (
                        <a href="https://goo.gl/maps/CyPqdKHT34u"
                          target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-map-marker"></i>
                          Ronda de Outeiro
                        </a>)</li>
                    </ul>
                  </article>
                  {/* /.service-single  */}
                </div>

                <div className="col-sm-4">
                  <article className="service-single">
                    <div className="service-icon">
                      <i className="fa fa-bus" aria-hidden="true"></i>
                    </div>
                    <h2 className="sub-title">Transporte</h2>
                    <ul>
                      <li>
                        <BusLinesInfo />
                      </li>
                      <li>Parada de Taxis 300 metros (
                        <a href="https://goo.gl/maps/WCwTHVicEQz"
                          target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-map-marker"></i>
                          calle Tornos
                        </a>)</li>
                    </ul>
                  </article>
                </div>
                <div className="col-sm-4">
                  <article className="service-single">
                    <div className="service-icon">
                      <i className="fa fa-car" aria-hidden="true"></i>
                    </div>
                    <h2 className="sub-title">Parking</h2>
                    <ul>
                      <li>
                        <a href="https://goo.gl/maps/tH4a7Mqcfut"
                          target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-map-marker"></i>
                          Parking as Conchiñas
                        </a> a 400 metros
                      </li>
                      <li>
                        <a href="https://goo.gl/maps/tH4a7Mqcfut"
                          target="_blank" rel="noopener noreferrer">
                          <i className="fa fa-map-marker"></i>
                          Párking Ronda de Outeiro
                        </a> 600 metros
                      </li>
                      <li>
                        Aparcamiento en la calle gratuito, sin zona azul ni zona verde.
                      </li>
                    </ul>
                  </article>
                  {/* /.service-single  */}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default NearbyServices
