// Font
// import './fonts/Open_Sans/OpenSans.css'
import './fonts/opensans/OpenSans.css'

// Bootrstap 3
// https://getbootstrap.com/docs/3.3
//  note: bootstrap 3 is still using LESS, so no SASS :(
//        TODO: try to upgrade to bootstrap 4 if posible
// @import "../vendor/bootstrap/custom";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap'

// Font awesome
import 'font-awesome/css/font-awesome.css'

// OWL Carrousel
// TODO: https://github.com/OwlCarousel2/OwlCarousel2
// @import 'vendor/jina/css/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.carousel.css'
// import 'owl.carousel'

// Magnific Popup
// TODO: Use NPM and review were is it used
// import './vendor/jina/css/magnific-popup.css'

// Revolution slider
// Todo review if it's worth it to complaint to info@themepunch.com
// The styles were with lots of resources missing, like
// Search for ANXO: Removed
// I end up using webpack copy plugin and load it from the HTML, to many bugs 
// and missing files!
// import './vendor/revolution/css/settings.css'
// import './vendor/revolution/css/layers.css'
// import './vendor/revolution/css/navigation.css'

// Main JINA css
import './vendor/jina/css/main.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

// TODO: Libs to clean!
/*
import './vendor/jina/js/jquery.waypoints.min.js'
import './vendor/jina/js/jquery.counterup.min.js'
import './vendor/jina/js/masonry.pkgd.min.js'
import './vendor/jina/js/isotope.pkgd.min.js'
*/

// TODO: can i delete this? too much trouble to add, not sure what they do
/*
import './vendor/revolution/js/jquery.themepunch.tools.min.js'
import './vendor/revolution/js/jquery.themepunch.revolution.js'
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.slideanims.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.layeranimation.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.navigation.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.video.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.actions.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.kenburn.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.migration.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.parallax.min.js"></script>
<script type="text/javascript" src="assets/revolution/js/extensions/revolution.extension.carousel.min.js"></script>

<!-- Google Map  
  <script type="text/javascript" src="js/gmap3.min.js"></script>
-->

  <!-- custom js 
    <script src="js/custom.js"></script>
    <script src="js/custom-map.js"></script>
  -->
*/