function loadScript ({
  src,
  async = true,
  defer = true,
  onload,
  ...extraParams
}) {
  if (!src) {
    throw new Error('src is required')
  }
  var scriptElement = document.createElement('script')
  Object.assign(scriptElement, extraParams, {
    src,
    async,
    defer,
    onload
  })
  document.body.appendChild(scriptElement)
}

export default loadScript
