// Since the API is so small, this file will do the service layer
import axios from 'axios'
const baseUrl = '/api/v1'

async function getAbout () {
  const result = await axios.get(baseUrl + '/about')
  return result.data
}

async function contact (contactParams) {
  await axios.post(baseUrl + '/contact', contactParams)
}

async function requestReservation (reservationParams) {
  await axios.post(baseUrl + '/reservations', reservationParams)
}

module.exports = {
  getAbout,
  contact,
  requestReservation
}
