import React from 'react'
import allAgesImg from '../../images/que-ofrecemos/_K3A3285.jpg'
import exclusiveForYouImg from '../../images/que-ofrecemos/_A3B1753.jpg'
import flexibleImg from '../../images/que-ofrecemos/_A3B1786.jpg'
import economicImg from '../../images/que-ofrecemos/_A3B1774.jpg'

class WhatDoWeOffer extends React.Component {
  render () {
    return (
      <div id="what-do-we-offer" className="section service-section">
        <div className="container-fluid">
          <div className="container section-header">
            <h1 className="title">¿Qué ofrecemos?</h1>
            <p className="sub-title">
              Festerra es un local diseñado y equipado para adaptarse a cualquier evento profesional, social o familiar con total intimidad
              y exclusividad. Hemos cuidado hasta el último detalle para que, tanto pequeños como adultos, disfruten de momentos
              divertidos y agradables en sus diferentes zonas.
            </p>
          </div>
          <div className="row">
            <div className="col-md-3">
              <article className="service-single">
                <h2 className="sub-title">Para todas las edades</h2>
                <figure>
                  <img src={ allAgesImg } alt="service" />
                </figure>

                <p>
                  Nuestro local está preparado y equipado para realizar
                  celebraciones de todo tipo ya sean infantiles, para amigos o
                  familia.<br />
                  Las instalaciones están pensadas para la comodidad de los
                  adultos mientras los pequeños se divierten con seguridad.
                  Nuestro espacio es amplio, luminoso, seguro y con unas
                  instalaciones elegantes y cuidadas, aptas para cualquier
                  celebración familiar o para eventos empresariales.
                </p>
              </article>
            </div>
            <div className="col-md-3">
              <article className="service-single">
                <h2 className="sub-title">En exclusiva para ti</h2>
                <figure>
                  <img src={ exclusiveForYouImg } alt="service" />
                </figure>

                <p>
                  En Festerra tienes todo el espacio en exclusiva para ti y no
                  pagas por persona, pagas por el local.
                  Dispones de un espacio con salón, comedor, aseos, cocina,
                  zona de juegos para niños y adultos.<br />Resérvalo el tiempo que
                  lo necesites, configúralo a tu gusto y compártelo con quien
                  quieras.<br />
                  Nuestro espacio se alquila de forma integral para celebrar un
                  solo evento, por lo que siempre tendrás total intimidad.
                </p>
                {/*
                  <a href="#" className="read-more pull-right">VIEW MORE</a>
                */}
              </article>
              {/*  /.service-single  */}
            </div>
            <div className="col-md-3">
              <article className="service-single">
                <h2 className="sub-title">Flexibilidad</h2>
                <figure>
                  <img src={ flexibleImg } alt="service" />
                </figure>

                <p>
                  Lo más importante es que celebres tu evento como tú quieras, por eso, lo hemos acondicionado para que no te falta de nada.
                  <br />
                  Te puedes encargar de organizar tú el evento a tu manera, puedes llevar tu propia comida o bebida, animación, decoración, etc. O pídenos ayuda para lo que necesites.<br />
                  Podemos facilitarte el contacto de profesionales de cátering, animación, decoración. Tú mandas. Si lo necesitas, te ayudamos.<br />
                  Nosotros no obligamos a nada.
                </p>
              </article>
            </div>

            <div className="col-md-3">
              <article className="service-single">
                <h2 className="sub-title">Económico y funcional</h2>
                <figure>
                  <img src={ economicImg } alt="service" />
                </figure>

                <p>
                  La forma más económica para celebrar cualquier evento.
                  Alquilas el local, por lo que pagas por el espacio y no por
                  persona.<br />
                  Puedes traer a quién quieras.<br />
                  La sala será solo para vosotros y tendréis todas las
                  comodidades (cocina, mesas, sillas, televisión, proyector,
                  karaoke, parque de bolas, futbolín, dardos…).<br />
                  Podrás reservar por horas o el día completo.
                </p>
              </article>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default WhatDoWeOffer
