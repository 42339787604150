import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import festerraLogoDescImg from '../../../images/festerra-logo-light-bg-descriptor.svg'
import festerraLogoImg from '../../../images/festerra-logo-light-bg.svg'
import SocialIcons from '../../quick-buttons/SocialIcons'

const $ = require('jquery')

var THRESHOLD_DELTA_HIDE_HEADER = 100
const THRESHOLD_SMALL_HEADER = 120

class Header extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      navBar: null
    }
  }

  componentDidMount () {
    var didScroll
    var lastScrollTop = 0

    // TODO: Improve: use react-like approach...
    const $body = $('body')
    const navBar = $('.navbar-collapse')
    navBar.on('show.bs.collapse', function () {
      $body.addClass('show-overlay')
    })
    navBar.on('hidden.bs.collapse', function () {
      $body.removeClass('show-overlay')
    })

    this.setState({
      navBar
    })

    this.hideShowHeader = () => {
      var scrollTop = $(document).scrollTop()
      if (scrollTop > THRESHOLD_SMALL_HEADER) {
        didScroll = true
        $body.removeClass('large-header').addClass('small-header')
      } else {
        $body.removeClass('small-header').addClass('large-header')
        navBar.collapse('hide')
      }
    }
    $(document).on('scroll', this.hideShowHeader)

    setInterval(function () {
      if (didScroll) {
        hasScrolled(this)
        didScroll = false
      }
    }, 250)

    function hasScrolled (component) {
      var scrollTop = $(component).scrollTop()
      var navbarHeight = $('header').outerHeight()

      // Make sure they scroll more than delta
      if (
        Math.abs(lastScrollTop - scrollTop) <=
                THRESHOLD_DELTA_HIDE_HEADER
      ) {
        return
      }

      // If they scrolled down and are past the navbar, add class .nav-up.
      // This is necessary so you never see what is "behind" the navbar.
      if (scrollTop > lastScrollTop && scrollTop > navbarHeight) {
        // Scroll Down
        $('header')
          .removeClass('nav-down')
          .addClass('nav-up')
        navBar.collapse('hide')
      } else {
        // Scroll Up
        if (scrollTop + $(window).height() < $(document).height()) {
          $('header')
            .removeClass('nav-up')
            .addClass('nav-down')
        }
      }

      lastScrollTop = scrollTop
    }
  }

  clickBurger (e) {
    const expanded = $('#navbar').attr('aria-expanded') === 'true'
    if (expanded) {
      // Opening menu, collapsing the header to make some room
      $('body')
        .removeClass('large-header')
        .addClass('small-header')
      $('header')
        .removeClass('nav-up')
        .addClass('nav-down')
    } else {
      // Closing menu
      this.hideShowHeader()
    }
    this.trackClickOn(e, 'Header: Responsive menu burger')
  }

  hideHeader (e, componentName) {
    this.state.navBar.collapse('hide')
    if (componentName) {
      this.trackClickOn(e, componentName)
    }
  }

  trackClickOn (event, componentName) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: componentName,
      eventAction: 'click',
      eventLabel: event.target.href
    })
  }

  render () {
    return (
      <header id="header" className="header large">
        <nav className="navbar navbar-default navbar-fixed-top">
          <div className="container-fluid">
            <div className="logo">
              <Link
                to="/"
                onClick={e =>
                  this.hideHeader(e, 'Header: Logo')
                }
              >
                <img src={festerraLogoDescImg} alt="logo" />
              </Link>
            </div>

            <div className="navbar-header">
              <button
                type="button"
                onClick={e => this.clickBurger(e)}
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
              >
                <span className="sr-only">
                                    Toggle navigation
                </span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>

              <SocialIcons />

              <div className="responsive-logo">
                <Link
                  to="/"
                  className="navbar-brand"
                  onClick={e =>
                    this.hideHeader(
                      e,
                      'Header: Responsive logo'
                    )
                  }
                >
                  <span>Menu</span>
                  <img src={festerraLogoImg} alt="logo" />
                </Link>
              </div>
            </div>

            <div className="navbar-collapse collapse" id="navbar">
              <div className="social-icons-menu-item">
                <SocialIcons />
              </div>
              <ul className="nav navbar-nav menu">
                <li>
                  <NavLink
                    exact
                    to="/"
                    activeClassName="active"
                    onClick={this.hideHeader.bind(this)}
                  >
                    <i className="fa fa-home hidden-lg hidden-md"></i>{' '}
                                        Inicio
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/que-ofrecemos"
                    activeClassName="active"
                    onClick={this.hideHeader.bind(this)}
                  >
                    <i className="fa fa-coffee hidden-lg hidden-md"></i>{' '}
                                        ¿Qué ofrecemos?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/como-funciona"
                    activeClassName="active"
                    onClick={this.hideHeader.bind(this)}
                  >
                    <i className="fa fa-picture-o hidden-lg hidden-md"></i>{' '}
                                        ¿Cómo funciona?
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/tarifas-y-reservas"
                    activeClassName="active"
                    onClick={this.hideHeader.bind(this)}
                  >
                    <i className="fa fa-calendar-check-o hidden-lg hidden-md"></i>{' '}
                                        Tarifas y reservas
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/galeria"
                    activeClassName="active"
                    onClick={this.hideHeader.bind(this)}
                  >
                    <i className="fa fa-picture-o hidden-lg hidden-md"></i>{' '}
                                        Galería
                  </NavLink>
                </li>
                <li>
                  <a
                    href="https://celebraenfesterra.wordpress.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-rss-square hidden-lg hidden-md"></i>{' '}
                                        Blog
                  </a>
                </li>
                <li>
                  <NavLink
                    to="/contacto"
                    activeClassName="active"
                    onClick={this.hideHeader.bind(this)}
                  >
                    <i className="fa fa-envelope-o hidden-lg hidden-md"></i>{' '}
                                        Contacto
                  </NavLink>
                </li>
              </ul>

              <SocialIcons />
            </div>
          </div>
        </nav>
      </header>
    )
  }
}

export default Header
