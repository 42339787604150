import React from 'react'

class CookiePolicy extends React.Component {
  render () {
    return (
      <div className="container cookie-policy">
        <div className="container-fluid">
          <div className="container section-header">
            <h1 className="title">
              Política de cookies
            </h1>
          </div>
          <div className="content">
            <p>
              Una Cookie es un fichero que se descarga en su ordenador al acceder a determinadas páginas web. Las cookies permiten a una página web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contenga y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario.
            </p>
            <p>
              Las cookies son esenciales para el funcionamiento de internet, aportando innumerables ventajas en la prestación de servicios interactivos, facilitándole la navegación y usabilidad de nuestra web.
            </p>
            <p>
              La información que le proporcionamos a continuación, le ayudará a comprender los diferentes tipos de cookies:
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th colSpan="3">Tipos de Cookies</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowSpan="2" scope="row">SEGÚN LA ENTIDAD QUE LAS GESTIONE</th>
                  <td className="table-title">Cookies propias</td>
                  <td>Son aquellas que se recaban por el propio editor para prestar el servicio solicitado por el usuario.</td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de tercero</td>
                  <td>Son aquellas que son recabadas y gestionadas por un tercero, estas no se pueden considerar propias.</td>
                </tr>

                <tr>
                  <th rowSpan="2" scope="row">SEGÚN EL PLAZO DE TIEMPO QUE PERMANEZCAN ACTIVADAS</th>
                  <td className="table-title">Cookies des sesión</td>
                  <td>Recaban datos mientras el usuario navega por la red con la finalidad de prestar el servicio solicitado.</td>
                </tr>
                <tr>
                  <td className="table-title">Cookies persistentes</td>
                  <td>Se almacenan en el terminal y la información obtenida, será utilizada por el responsable de la cookie con la finalidad de prestar el servicio solicitado.</td>
                </tr>

                <tr>
                  <th rowSpan="5" scope="row">SEGÚN SU FINALIDAD</th>
                  <td className="table-title">Cookies técnicas</td>
                  <td>Son las necesarias para la correcta navegación por la web.</td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de personalización</td>
                  <td>Permiten al usuario las características (idioma) para la navegación por la website.</td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de análisis</td>
                  <td>Permiten al prestador el análisis vinculado a la navegación realizada por el usuario, con la finalidad de llevar un seguimiento de uso de la página web, así como realizar estadísticas de los contenidos más visitados, número de visitantes, etc.</td>
                </tr>
                <tr>
                  <td className="table-title">Cookies publicitarias</td>
                  <td>Permiten al editor incluir en la web, espacios publicitarios, según el contenido de la propia web.</td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de publicidad comportamental</td>
                  <td>Permiten al editor incluir en la página web espacios publicitarios según la información obtenida a través de los hábitos de navegación del usuario.</td>
                </tr>
              </tbody>
            </table>

            <p>
              Según lo dispuesto en el artículo 22.2 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI-CE), FESTERRA informa de las cookies utilizadas en nuestra web:
            </p>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th colSpan="2" rowSpan="2"></th>
                  <th colSpan="4">Tipos de Cookies</th>
                </tr>
                <tr>
                  <td className="table-title">Cookies propias</td>
                  <td className="table-title">Cookies de terceros</td>
                  <td className="table-title">Cookies de sesión</td>
                  <td className="table-title">Cookies persistentes</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th rowSpan="5" scope="row">Finalidad</th>
                  <td className="table-title">Cookies técnicas</td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de personalización</td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de análisis</td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                  <td><input type="checkbox" disabled checked="checked" /></td>
                </tr>
                <tr>
                  <td className="table-title">Cookies publicitarias</td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                </tr>
                <tr>
                  <td className="table-title">Cookies de publicidad comportamental</td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                  <td><input type="checkbox" disabled /></td>
                </tr>
              </tbody>
            </table>

            <p>
              La aplicación que utilizamos para obtener y analizar la información de la navegación es: <strong>Google Analytics</strong>.
            </p>
            <p>
              Puede consultar su política de cookies a través de los siguientes enlaces:
            </p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage">https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage</a></li>
              <li><a target="_blank" rel="noopener noreferrer" href="https://support.google.com/analytics/answer/6004245">https://support.google.com/analytics/answer/6004245</a></li>
            </ul>
            <p>
              Asimismo, FESTERRA informa al usuario de que tiene la posibilidad de configurar su navegador de modo que se le informe de la recepción de cookies, pudiendo, si así lo desea, impedir que sean instaladas en su disco duro.
            </p>
            <p>
              A continuación le proporcionamos los enlaces de diversos navegadores, a través de los cuales podrá realizar dicha configuración:
            </p>
            <p>
              Firefox desde aquí:
            </p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a></li>
            </ul>

            <p>
              Chrome desde aquí:
            </p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647">http://support.google.com/chrome/bin/answer.py?hl=es&answer=95647</a></li>
            </ul>

            <p>
              Explorer desde aquí:
            </p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10">http://windows.microsoft.com/es-es/internet-explorer/delete-manage-cookies#ie=ie-10</a></li>
            </ul>

            <p>
              Safari desde aquí:
            </p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="http://support.apple.com/kb/ph5042">http://support.apple.com/kb/ph5042</a></li>
            </ul>

            <p>
              Opera desde aquí:
            </p>
            <ul>
              <li><a target="_blank" rel="noopener noreferrer" href="http://help.opera.com/Windows/11.50/es-ES/cookies.html">http://help.opera.com/Windows/11.50/es-ES/cookies.html</a></li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default CookiePolicy
