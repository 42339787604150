import React from 'react'

import Carousel from '../content/Carousel'
import WhatDoWeOffer from '../content/WhatDoWeOffer'
import OurFacilities from '../content/OurFacilities'
import NearbyServices from '../content/NearbyServices'
import HowDoesItWork from '../content/HowDoesItWork'
import PricesAndContactBtns from '../quick-buttons/PricesAndContactBtns'

class HomePage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // message: 'Hello world from class component!'
    }
  }
  render () {
    return (
      <div className="home">
        <Carousel />
        <PricesAndContactBtns />
        <WhatDoWeOffer />
        <OurFacilities />
        <NearbyServices />
        <HowDoesItWork />
        {/*
        <Pricing />
        <Avaliability />
        <Reservation />
        <ReturnPolicy />
      */}
        <PricesAndContactBtns />
      </div>
    )
  }
}

export default HomePage
