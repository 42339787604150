import React from 'react'

class InstallationsUsagePolicy extends React.Component {
  render () {
    return (
      <div className="container about">
        <div className="container-fluid">
          <div className="container section-header">
            <h1 className="title">
              Condiciones de uso
            </h1>
          </div>
          <div className="content">
            <h3>Uso de las instalaciones</h3>
            <ul>
              <li>Se comunica al cliente que el local dispone de un sistema de videovigilancia en las instalaciones de uso colectivo.</li>
              <li>El cliente será el responsable del uso adecuado de las instalaciones. Por ello, deberá de responsabilizarse de la utilización correcta del espacio y será responsable de los daños y desperfectos en las instalaciones, mobiliario, suministros y objetos del local, y de todas las personas que se encuentren en el local mientras dure el evento.</li>
              <li>La puerta del local deberá permanecer cerrada mientras dura el evento para impedir el paso a personas no autorizadas o desconocidas.</li>
              <li>El cliente será responsable de los menores de edad y del uso adecuado de la zona para niños. No podrán acceder con comida o bebida.</li>
              <li>En caso de que el cliente acceda al local con su propia comida y bebida, será responsable de las posibles incidencias con respecto a la misma: intoxicaciones alimentarias, derrames, limpieza… comprometiéndose a retirar estos productos una vez finalice el evento y el alquiler del local.</li>
              <li>Los servicios adicionales contratados tendrán un precio añadido cuyo importe se fijará en el momento de su contratación.</li>
              <li>Es obligatorio respetar las edades y aforo máximo de la sala y del parque de bolas.</li>
              <li>Durante toda la fiesta es imprescindible que haya 1 persona mayor de edad en el local.</li>
            </ul>

            <h3>Limitaciones de uso</h3>
            <strong>No está permitido:</strong>
            <ul>
              <li>Superar el aforo del local.</li>
              <li>Fumar en ningún lugar de la instalación.</li>
              <li>La organización de cualquier evento o acto diferente al que se ha descrito por el cliente en el momento de formalizar el contrato de alquiler.</li>
              <li>El almacenamiento, tenencia o utilización de cualquier sustancia inflamable o peligrosa, materiales combustibles, fuegos artificiales, petardos o cualquier tipo de pirotecnia.</li>
              <li>La utilización de cualquier zona, espacio, elemento decorativo, mobiliario, objeto de uso culinario y suministros con una finalidad distinta a la que son destinados, ni la utilización de estos elementos fuera del local.</li>
              <li>La utilización de las instalaciones fuera de las horas de alquiler.</li>
              <li>El acceso y utilización de la zona infantil por personas adultas.</li>
              <li>El acceso y utilización de elementos como patines, monopatines, bicicletas, juegos cortantes o similares.</li>
              <li>El acceso al local con animales, salvo perros guía.</li>
              <li>La utilización de música, reproductores de sonido, altavoces y amplificadores propios y que no sean los que están a disposición del cliente dentro de las instalaciones.</li>
              <li>Pegar en la pared y decorar fuera de los sitios indicados. No se pueden realizar agujeros, ni poner chinchetas, clavos, pegatinas ni similares.</li>
              <li>No está permitido utilizar la calle como extensión del local. Tampoco consumir bebida o comida fuera del local.</li>
              <li>No se realizarán actividades ilegales ni se consumirán sustancias ilegales.</li>
              <li>Cesión del contrato de alquiler sin la autorización expresa y escrita del arrendador.</li>
            </ul>

            <h3>Responsabilidad en el uso del local</h3>
            <ul>
              <li>El cliente, en el momento en el que accede al local para celebrar su evento, será el que asuma la responsabilidad de sus acciones y las de sus invitados. Si por hacer un uso inadecuado de las instalaciones, se produjesen incidentes que afectasen al local, a las propias personas que están dentro o a los vecinos de cualquier forma, responderá de sus actos con las consecuencias legales y económicas que se deriven de todo ello.</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default InstallationsUsagePolicy
