import './vendor'
import './main.scss'

import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

// Layout and utils
import Header from './components/pages/layout/Header'
import Footer from './components/pages/layout/Footer'
import ScrollToTop from './components/utils/ScrollToTop'
import history from './history'

// Main sections
import Home from './components/pages/HomePage'
import WhatDoWeOffer from './components/pages/WhatDoWeOfferPage'
import HowDoesItWorkPage from './components/pages/HowDoesItWorkPage'
import PricingAndReservation from './components/pages/PricingAndReservationPage'
import GalleryPage from './components/pages/GalleryPage'
import Contact from './components/pages/ContactPage'

// Policies
import Imprint from './components/pages/policies/Imprint'
import InstallationsUsagePolicy from './components/pages/policies/InstallationsUsagePolicy'
import CookiePolicy from './components/pages/policies/CookiePolicy'
import PrivacyPolicy from './components/pages/policies/PrivacyPolicy'

// Other
import NotFound from './components/pages/NotFoundPage'
// import Landing from './components/pages/LandingPage'
import AboutPage from './components/pages/AboutPage'

const publicUrl = process.env.PUBLIC_URL || '/'

class App extends React.Component {
  constructor (props) {
    super(props)

    this.state = {}
  }

  clickOnOverlay () {
    // TODO: Improve
    const $ = require('jquery')
    const navBar = $('.navbar-collapse')
    navBar.collapse('hide')
  }

  render () {
    return (
      <React.Fragment>
        <div id="overlay" onClick={ e => this.clickOnOverlay(e) } />
        <Route path="/" component={ Header }/>
        <main>
          <Switch>
            <Route exact path="/" component={ Home }/>
            <Route path="/que-ofrecemos" component={ WhatDoWeOffer }/>
            <Route path="/como-funciona" component={ HowDoesItWorkPage }/>
            <Route path="/tarifas-y-reservas" component={ PricingAndReservation }/>
            <Route path="/galeria" component={ GalleryPage }/>

            <Route path="/contacto" component={ Contact }/>
            <Route path="/aviso-legal" component={ Imprint }/>
            <Route path="/condiciones-de-uso" component={ InstallationsUsagePolicy }/>
            <Route path="/politica-de-cookies" component={ CookiePolicy }/>
            <Route path="/politica-de-privacidad" component={ PrivacyPolicy }/>
            <Route path="/about" component={ AboutPage }/>
            <Route path="/" component={ NotFound }/>
          </Switch>
        </main>
        <Route path="/" component={ Footer }/>
      </React.Fragment>
    )
  }
}

ReactDOM.render(
  <BrowserRouter history={ history } basename={publicUrl}>
    <ScrollToTop>
      <App />
    </ScrollToTop>
  </BrowserRouter>,
  document.querySelector('#app')
)

function loadApp () {
  return import('./analytics')
    .then(({ default: analytics }) => analytics.init())
    .catch(console.error)
}

loadApp()
