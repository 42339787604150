import React from 'react'
import './AboutPage.scss'
import api from '../../api'

class AboutPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      version: null,
      environment: null,
      git: {}
    }
    api
      .getAbout()
      .then(about => {
        console.log(about)
        this.setState({
          ...about,
          version: VERSION,
          backendVersion: about.version
        })
      })
  }

  render () {
    const { version, backendVersion, environment, git } = this.state
    return (
      <div className="container about">
        <div className="container-fluid">
          <div className="container section-header">
            <h1 className="title">Sobre Festerra.com</h1>
          </div>
          <div className="content">
            <table>
              <tbody>
                <tr>
                  <th>Versión:</th>
                  <td>{ version }</td>
                </tr>
                <tr>
                  <th>Backend:</th>
                  <td>{ backendVersion }</td>
                </tr>
                <tr>
                  <th>Entorno:</th>
                  <td>{ environment }</td>
                </tr>
                <tr>
                  <th>Git short:</th>
                  <td>{ git.short }</td>
                </tr>
                <tr>
                  <th>Git long:</th>
                  <td>{ git.long }</td>
                </tr>
                <tr>
                  <th>Git branch:</th>
                  <td>{ git.branch }</td>
                </tr>
                <tr>
                  <th>Git tag:</th>
                  <td>{ git.tag }</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

export default AboutPage
