import React from 'react'

class CookiePolicy extends React.Component {
  render () {
    return (
      <div className="container">
        <div className="container-fluid">
          <div className="container section-header">
            <h1 className="title">
              Política de privacidad
            </h1>
          </div>
          <div className="content">
            <p>
              Nuestra Política de Privacidad establece los términos en que <strong>Festerra</strong> usa y protege la información que es proporcionada por los usuarios cuando usan nuestra página web.
            </p>
            <p>
              Cuando te pedimos rellenar los campos de información personal para contactar con nosotros, lo hacemos asegurando que solo se empleará de acuerdo con los términos de este documento.
            </p>
            <p>
              Sin embargo, esta Política de Privacidad puede cambiar con el tiempo o ser actualizada por lo que te recomendamos que la revises antes para asegurarte que estás de acuerdo con dichos cambios.
            </p>

            <h3>¿Quién es el Responsable del Tratamiento?</h3>
            <ul>
              <li><strong>Identidad</strong>: Hermanos Rodríguez Fernández CB</li>
              <li><strong>Dirección</strong>: Avenida de Finisterre, 196</li>
              <li>
                <strong>Correo electrónico</strong>:&nbsp;
                <a href="mailto:reservas@festerra.com">reservas@festerra.com</a>
              </li>
            </ul>

            <h3>¿Con qué finalidad tratamos tus datos?</h3>
            <p>Los datos que nos facilitas a través del formulario de contacto son tratados e incorporados a nuestra base de datos con la finalidad de atender las consultas realizadas o incluso ponernos en contacto contigo por los medios facilitados para informarte de aspectos relativos a los productos y servicios que nos solicitas.</p>
            <p>Es posible que en alguna ocasión te enviemos correos electrónicos con ofertas especiales, nuevos productos u otra información publicitaria que consideremos relevante para ti o que pueda brindarte algún beneficio. Estos correos electrónicos serán enviados a la dirección que nos proporciones y podrán ser cancelados en cualquier momento.</p>

            <h3>¿A quién se comunican sus datos?</h3>
            <p>Los datos recibidos mediante los formularios de recogida de datos se reciben a través de un sistema de correo electrónico que cumple con las medidas de seguridad adecuadas.</p>
            <p><strong>Festerra</strong> no venderá, cederá ni distribuirá la información personal que es recopilada sin tu consentimiento, salvo que sea requerido por un juez con un orden judicial. Los datos objeto de tratamiento pueden ser comunicados a las autoridades administrativas con competencia en la materia dentro de los supuestos legalmente previstos y además en los casos en que dicha comunicación es necesaria para poder prestar los servicios solicitados.</p>

            <h3>Cámaras de seguridad</h3>
            <p>De acuerdo con lo establecido en el art. 13 del  REGLAMENTO (UE) 2016/679 DEL PARLAMENTO EUROPEO Y DEL CONSEJO  de 27 de abril de 2016  relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protección de datos)  le informamos que el inmueble objeto de arrendamiento cuenta con un sistema de videovigilancia cuyo objeto consiste en el control de las obligaciones del presente contrato, la delimitación de responsabilidades y la seguridad del inmueble y de sus ocupantes. No existe captación de audio ni hay cámaras susceptibles de invadir espacios íntimos. El arrendatario se compromete a mantener las cámaras despejadas. Le informamos que las imágenes, en tanto datos de carácter personal, serán incorporadas a un tratamiento cuyo responsable es <strong>Festerra</strong>, con el fin antedicho al amparo de lo previsto en el art. 1258 de nuestro Código Civil. Sus datos están seguros y serán tratados confidencialmente por un plazo que nos permita acreditar o garantizar el cumplimiento de nuestras recíprocas obligaciones que nunca será superior a los 30 días y, salvo que usted nos autorice o una ley lo imponga, no serán cedidos a nadie, ni publicados o transferidos a terceros países. Además, tiene usted derecho a acceder a ellos, suprimirlos o limitar su tratamiento dirigiéndose a la dirección del Delegado de Protección de Datos. También podrá presentar una reclamación ante la Agencia Española de Protección de Datos si considera que sus datos no han sido tratados conforme a la meritada norma. En su calidad de arrendatario asume la obligación de informar a sus invitados de la cláusula anterior.</p>

            <h3>¿Cuáles son tus derechos?</h3>
            <p>El titular de los datos tiene derecho a:</p>
            <ul>
              <li>Acceder a sus datos</li>
              <li>Rectificar sus datos</li>
              <li>Suprimir sus datos</li>
              <li>Portar sus datos a otro Responsable</li>
              <li>Oponerse a todo o parte del tratamiento</li>
              <li>Limitar el uso de sus datos personales</li>
            </ul>
            <p>
              Puedes ejercer los derechos de acceso, cancelación, rectificación y oposición mediante un escrito a la dirección:&nbsp;
              <a href="mailto:reservas@festerra.com">reservas@festerra.com</a>.
              En caso de no satisfacer tus derechos, puedes presentar reclamación ante la Autoridad de Protección de Datos.
            </p>
          </div>
        </div>
      </div>
    )
  }
}

export default CookiePolicy
