import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import festerraLogoImg from '../../../images/festerra-logo-dark-bg.svg'

class Footer extends React.Component {
  render () {
    return (
      <footer id="footer" className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="widget widget_about">
                <div className="widget-logo">
                  <Link to="/" onClick={ e => this.trackClickOn(e, 'Footer: Logo') }>
                    <img src={ festerraLogoImg } alt="footer" />
                  </Link>
                </div>
                <p>
                  Espacio privado para eventos
                </p>
                <div className="social">
                  <a href="https://www.facebook.com/festerra/"
                    onClick={ e => this.trackClickOn(e, 'Footer: facebook btn') }
                    target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-facebook"></i>
                  </a>
                  <a href="https://www.instagram.com/festerra_/"
                    onClick={ e => this.trackClickOn(e, 'Footer: instagram btn') }
                    target="_blank" rel="noopener noreferrer">
                    <i className="fa fa-instagram"></i>
                  </a>
                </div>
              </div>
              {/* .widget_about */}
            </div>
            <div className="col-md-4">
              <div className="widget widget_contact">
                <h2 className="widget-title">Contacto</h2>
                <ul className="list-unstyled">
                  <li>
                    <a onClick={ e => this.trackClickOn(e, 'Footer: Contact') }
                      href="https://www.google.com/maps/search/?api=1&query=Avenida%20de%20Finisterre,%20196.%2015010,%20A%20Coruña"
                      target="_blank" rel="noopener noreferrer">
                      <div className="media-left">
                        <i className="fa fa-map-marker"></i>
                      </div>
                      <div className="media-body">
                        Avenida de Finisterre, 196
                        <br /> 15010, A Coruña
                      </div>
                    </a>
                  </li>
                  <li>
                    <HashLink
                      smooth
                      to="/tarifas-y-reservas#reservas"
                      onClick={ e => this.trackClickOn(e, 'Footer: Contact') }>
                      <div className="media-left">
                        <i className="fa fa-calendar"></i>
                      </div>
                      <div className="media-body">
                        Reservas
                      </div>
                    </HashLink>
                  </li>
                  <li>
                    <Link
                      to="/contacto"
                      onClick={ e => this.trackClickOn(e, 'Footer: Contact') }>
                      <div className="media-left">
                        <i className="fa fa-envelope"></i>
                      </div>
                      <div className="media-body">
                        Contacto
                      </div>
                    </Link>
                  </li>
                  <li>
                    <a href="https://festerra.com" onClick={ e => this.trackClickOn(e, 'Footer: Contact') }>
                      <div className="media-left">
                        <i className="fa fa-globe"></i>
                      </div>
                      <div className="media-body">
                        festerra.com
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
              {/* .widget_contact */}
            </div>

            <div className="col-md-4">
              <div className="widget widget_recent_entries">
                <h2 className="widget-title">Links rápidos</h2>
                <ul>
                  <li>
                    <Link
                      to="/tarifas-y-reservas"
                      onClick={ e => this.trackClickOn(e, 'Footer: Quick links') }>
                      Tarifas y reservas
                    </Link>
                    <span>Comprueba la disponibilidad y solicita tu reserva.</span>
                  </li>
                  <li>
                    <Link
                      to="/que-ofrecemos"
                      onClick={ e => this.trackClickOn(e, 'Footer: Quick links') }>
                      ¿Qué ofrecemos?
                    </Link>
                    <span>Consulta los servicios que Festerra te ofrece.</span>
                  </li>
                  <li>
                    <Link
                      to="/contacto"
                      onClick={ e => this.trackClickOn(e, 'Footer: Quick links') }>
                      Contacto
                    </Link>
                    <span>Para cualquier consulta, duda o sugerencia.</span>
                  </li>
                </ul>
              </div>
              {/* .widget_recent_entries */}
            </div>
          </div>

          <div className="row policies">
            <Link to="/">Página principal</Link>
            &nbsp;-&nbsp;
            <Link to="/aviso-legal">Aviso legal</Link>
            &nbsp;-&nbsp;
            <Link to="/condiciones-de-uso">Condiciones de uso</Link>
            &nbsp;-&nbsp;
            <Link to="/politica-de-privacidad">Política de privacidad</Link>
            &nbsp;-&nbsp;
            <Link to="/politica-de-cookies">Política de cookies</Link>
          </div>
          <div className="version">Version {VERSION}</div>
        </div>
      </footer>
    )
  }

  trackClickOn (event, componentName) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: componentName,
      eventAction: 'click',
      eventLabel: event.target.href
    })
  }
}

export default Footer
