import React from 'react'
import { Link } from 'react-router-dom'

function trackClickOn (event, componentName) {
  window.ga('send', {
    hitType: 'event',
    eventCategory: componentName,
    eventAction: 'click'
    // eventLabel: event.target.href
  })
}

const PricesAndContactBtns = () => (
  <div className="quick-buttons">
    <Link
      to="/tarifas-y-reservas"
      onClick={ e => trackClickOn(e, 'Quick buttons - Price and Reservation') }
      className="btn btn-primary btn-lg">
      <i className="fa fa-calendar-check-o" aria-hidden="true"></i> Tarifas y reservas
    </Link>
    <Link
      to="/contacto"
      className="btn btn-default btn-lg"
      onClick={ e => trackClickOn(e, 'Quick buttons - Contact') }>
      <i className="fa fa-envelope-o" aria-hidden="true"></i> Contacto
    </Link>
  </div>
)

export default PricesAndContactBtns
