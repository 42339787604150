import React from 'react'

class BusLinesInfo extends React.Component {
  render () {
    return (
      <React.Fragment>
        <strong>Parada 192</strong>. Líneas&nbsp;
        <a href="http://www.tranviascoruna.com/en/lineas-y-horarios/?linea=6"
          target="_blank" rel="noopener noreferrer">
          6
        </a>,&nbsp;
        <a href="http://www.tranviascoruna.com/en/lineas-y-horarios/?linea=6a"
          target="_blank"
          rel="noopener noreferrer">
          6A
        </a>
        &nbsp;y&nbsp;
        <a href="http://www.tranviascoruna.com/en/lineas-y-horarios/?linea=22"
          target="_blank"
          rel="noopener noreferrer">
          22
        </a> delante de la puerta.
      </React.Fragment>
    )
  }
}

export default BusLinesInfo
