import React from 'react'
import { HashLink } from 'react-router-hash-link'
import ContactForm from './ContactForm'
import BusLinesInfo from './BusLinesInfo'
import { PHONE_HREF, PHONE_NUMBER } from '../../constants'

class Contact extends React.Component {
  render () {
    return (
      <div id="contact2" className="section contact2-section m-tb120">
        <div className="container">
          <div className="container section-header">
            <h1 className="title"><a className="whatsapp" href={PHONE_HREF}><i className="fa fa-whatsapp"></i></a> Tenemos WhatsApp!</h1>
            <p className="sub-title">
              <ul>
                <li>Te atendemos por WhatsApp en horario de tarde en el <a className="whatsapp" href={PHONE_HREF}>{PHONE_NUMBER}&nbsp;<i className="fa fa-whatsapp"></i></a></li>
                <li>
                  Si lo que quieres es hacer una reserva, contáctanos mediante el&nbsp;
                  <HashLink smooth to="/tarifas-y-reservas#reservas">
                    formulario de reservas
                  </HashLink>
                </li>
                <li>Para cualquier otra consulta o comentario, usa el siguiente formulario</li>
              </ul>
              {/* Si lo que quiere es hacer una reserva, contactenos mediante el&nbsp;
              <HashLink smooth to="/tarifas-y-reservas#reservas">
                formulario de reservas
              </HashLink>. */}
            </p>

            <h2 className="title">Formulario de contacto</h2>
          </div>
          <div className="row">
            <div className="col-md-3 hidden-sm hidden-xs">
              {/*  col-sm-12  */}
              <div className="location">

                {/* <div className="item">
                  <a className="whatsapp" href={phoneValue}><i className="fa fa-whatsapp"></i></a>
                  <h6>Te atendemos por <a href={phoneValue} target="_blank" rel="noopener noreferrer">WhatsApp</a></h6>
                  <p>
                    Escribenos al <a target="_blank" rel="noopener noreferrer" href={phoneValue}>{PHONE_NUMBER}</a>
                  </p>
                </div> */}

                <div className="item">
                  <i className="fa fa-car"></i>
                  <h6>VISÍTANOS</h6>
                  <p>
                    <a href="https://www.google.com/maps/search/?api=1&query=Avenida%20de%20Finisterre,%20196.%2015010,%20A%20Coruña"
                      target="_blank" rel="noopener noreferrer">
                      Avenida de Finisterre, 196
                      <br /> 15010, A Coruña
                    </a>
                  </p>
                </div>

                <div className="item">
                  <i className="fa fa-bus"></i>
                  <h6>BUS</h6>
                  <p>
                    <BusLinesInfo />
                  </p>
                </div>

                <div className="item">
                  <i className="fa fa-calendar"></i>
                  <h6>RESERVAS</h6>
                  <p>
                    <HashLink smooth to="/tarifas-y-reservas#reservas">
                      Formulario de reservas
                    </HashLink>.
                  </p>
                </div>

                {/*
                <div className="item">
                  <i className="fa fa-facebook"></i>
                  <h6>FACEBOOK</h6>
                  <p>
                    <a href="https://www.facebook.com/festerra/"
                      onClick={ e => this.trackClickOn(e, 'Contact: facebook btn') }
                      target="_blank" rel="noopener noreferrer">
                      Siguenos en Facebook
                    </a>
                  </p>
                </div>
                */}

              </div>
            </div>

            <div className="col-md-7 col-md-offset-1 col-sm-12">
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    )
  }

  trackClickOn (event, componentName) {
    window.ga('send', {
      hitType: 'event',
      eventCategory: componentName,
      eventAction: 'click',
      eventLabel: event.target.href
    })
  }
}

export default Contact
