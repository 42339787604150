import React from 'react'

import Pricing from '../content/Pricing'
// import Avaliability from '../content/Avaliability'
import Reservation from '../content/Reservation'
import ReturnPolicy from '../content/ReturnPolicy'
import HowDoesItWork from '../content/HowDoesItWork'

class PricingAndReservationPage extends React.Component {
  render () {
    return (
      <React.Fragment>
        <div className="page-title">
          <h2>Tarifas</h2>
        </div>
        <Pricing />
        {/* <Avaliability /> */}
        <Reservation />
        <HowDoesItWork />
        <ReturnPolicy />
      </React.Fragment>
    )
  }
}

export default PricingAndReservationPage
