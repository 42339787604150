import Slick from 'react-slick'
import React from 'react'

import './Carousel.scss'
import carrusel1 from '../../images/carousel/carousel_1_big.jpg'
import carrusel2 from '../../images/carousel/carousel_2_big.jpg'
import carrusel3 from '../../images/carousel/carousel_3_big.jpg'
import carrusel4 from '../../images/carousel/carousel_4_big.jpg'

class Carousel extends React.Component {
  render () {
    var settings = {
      dots: true,
      fade: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      speed: 3000,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      draggable: true,
      // afterChange: () => console.log('afterChange'),
      // beforeChange: () => console.log('beforeChange'),
      // breakpoint: () => console.log('breakpoint change'),
      // destroy: () => console.log('destroy'),
      // edge: () => console.log('edge'),
      // init: () => console.log('init'),
      // setPosition: () => console.log('setPosition'),
      // lazyLoaded: () => console.log('lazyLoaded'),
      // lazyLoadError: () => console.log('lazyLoadError'),
      // reInit: () => console.log('reInit'),
      // swipe: () => console.log('swipe'),
      // lazyLoad: 'ondemand',
      // mobileFirst: false,
      // pauseOnFocus: true
      // pauseOnHover: true
      respondTo: 'slider' // window, slider
      // swipeToSlide: false
      // touchMove: true
      // touchThreshold: true
    }

    return (
      <div className="carousel-wrapper">
        <Slick {...settings}>
          <div className="carousel-item slide-1">
            <img src={carrusel1} />
            <div className="carousel-label middle">
              <h2>
                                Nosotros ponemos la sala, tú pones la fiesta
              </h2>
              <p className="sub-title">
                                Alquila en A Coruña tu espacio privado y único
                                para cualquier tipo de evento
              </p>
            </div>
            <div className="carousel-overlay" />
          </div>
          <div className="carousel-item slide-2">
            <div className="carousel-label bottom">
              <h2>Celebra lo que quieras</h2>
              <p className="sub-title">
                                Reuniones familiares, bautizos, cenas, ver ese
                                partidazo de tu equipo favorito o lo que a ti te
                                apetezca
              </p>
            </div>
            <img src={carrusel2} />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item slide-3">
            <div className="carousel-label top">
              <h2>Cumpleaños diferentes</h2>
              <p className="sub-title">
                                La diversión de un parque de bolas, la intimidad
                                del salón de tu casa.
              </p>
            </div>
            <img src={carrusel3} />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item slide-4">
            <div className="carousel-label bottom">
              <h2>Eventos profesionales</h2>
              <p className="sub-title">
                                Totalmente equipado y con flexibilidad horaria
                                para impartir talleres o cursos.
              </p>
            </div>
            <img src={carrusel4} />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item slide-5">
            <div className="blog-img-1" />
            <div className="blog-img-2" />
            <div className="blog-img-3" />
            <div
              className="carousel-label bottom"
              onClick={() =>
                window.open(
                  'https://celebraenfesterra.wordpress.com',
                  '_blank'
                )
              }
            >
              <p className="sub-title">
                                Siguenos en nuestro blog{', '}
                <a
                  href="https://celebraenfesterra.wordpress.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className="fa fa-rss-square hidden-lg hidden-md"></i>{' '}
                                    Celebra en festerra
                </a>
              </p>
            </div>
          </div>
        </Slick>
      </div>
    )
  }
}
/*
    return (
      <div className="carousel-wrapper">
        <Slick {...settings}>
          <div className="carousel-item">
            <div className="carousel-label" style={{
              position: 'absolute',
              top: '35%',
              left: '15%',
              width: '70%'
            }}>
              <h2>Nosotros ponemos la sala, tú pones la fiesta</h2>
              <p className="sub-title">
                Alquila en A Coruña tu espacio privado y único para cualquier tipo de evento
              </p>
            </div>
            <img src={ carrusel1 } />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item">
            <div className="carousel-label" style={{
              position: 'absolute',
              top: '10%',
              left: '1%'
              // minHeight: '13vw',
            }}>
              <h2>Texto 2 bla bla</h2>
              <p className="sub-title">
                bla, bla, bla, bla, bla, bla, bla, bla, ....
              </p>
            </div>
            <img src={ carrusel2 } />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item">
            <div className="carousel-label" style={{
              position: 'absolute',
              top: '0%',
              left: '25%',
              width: '50%'
            }}>
              <h2>Texto 3 bla bla</h2>
              <p className="sub-title">
                bla, bla, bla, bla, bla, bla, bla, bla, ....
              </p>
            </div>
            <img src={ carrusel3 } />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item">
            <div className="carousel-label" style={{
              position: 'absolute',
              bottom: '0%',
              left: '50%',
              width: '50%'
            }}>
              <h2>Texto 4 bla bla</h2>
              <p className="sub-title">
                bla, bla, bla, bla, bla, bla, bla, bla, ....
              </p>
            </div>
            <img src={ carrusel4 } />
            <div className="carousel-overlay" />
          </div>

          <div className="carousel-item">
            <div className="carousel-label" style={{
              position: 'absolute',
              top: '0%',
              right: '0%',
              width: '30%',
              height: '100%'
            }}>
              <h2>Texto 5 bla bla</h2>
              <p className="sub-title">
                bla, bla, bla, bla, bla, bla, bla, bla, ....
              </p>
            </div>
            <img src={ carrusel5 } />
            <div className="carousel-overlay" />
          </div>
        </Slick>
      </div>
    )
  }
*/

export default Carousel
