import React from 'react'
import Gallery from 'react-photo-gallery'
import Lightbox from 'react-images'
import { images } from './GalleryPageData'
const PUBLIC_URI = '/images/gallery'
const galleryImages = images.map(({
  baseName,
  extension,
  width,
  height
}) => ({
  src: `${PUBLIC_URI}/${baseName}_gallery.${extension}`,
  width,
  height
}))

const previewImages = images.map(({
  baseName,
  extension
}) => ({
  src: `${PUBLIC_URI}/${baseName}_preview.${extension}`
}))

// TODO: http://neptunian.github.io/react-photo-gallery/examples/srcset-and-sizes.html

const smallDevice = document.body.offsetWidth <= 375

class GalleryPage extends React.Component {
  constructor (props) {
    super(props)

    this.state = { currentImage: 0 }
    this.closeLightbox = this.closeLightbox.bind(this)
    this.openLightbox = this.openLightbox.bind(this)
    this.gotoNext = this.gotoNext.bind(this)
    this.gotoPrevious = this.gotoPrevious.bind(this)
  }

  componentDidMount () {
  }

  openLightbox (event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    })
  }
  closeLightbox () {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    })
  }
  gotoPrevious () {
    this.setState({
      currentImage: this.state.currentImage - 1
    })
  }
  gotoNext () {
    this.setState({
      currentImage: this.state.currentImage + 1
    })
  }

  render () {
    return (
      <React.Fragment>
        <div className="page-title">
          <h2>Galería de fotos</h2>
        </div>
        <Gallery
          photos={ galleryImages }
          onClick={this.openLightbox}
        />
        { !smallDevice && (
          <Lightbox images={ previewImages }
            onClose={this.closeLightbox}
            onClickPrev={this.gotoPrevious}
            onClickNext={this.gotoNext}
            currentImage={this.state.currentImage}
            isOpen={this.state.lightboxIsOpen}
          />
        )}
      </React.Fragment>
    )
  }
}

export default GalleryPage
