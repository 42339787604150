import React from 'react'

class Imprint extends React.Component {
  render () {
    return (
      <div className="container">
        <div className="container-fluid">
          <div className="container section-header">
            <h1 className="title">
              Aviso legal
            </h1>
          </div>
          <div className="content">
            <h3>Titularidad de la Página Web</h3>
            <p>
              De acuerdo con el artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio se informa al usuario que la página web es titularidad de
              &nbsp;<strong>HERMANOS RODRÍGUEZ FERNÁNDEZ CB</strong>. con CIF <strong>E70512249</strong>, domicilio en calle Alfredo Vicenti, 12, el cual es responsable único y exclusivo en relación a la veracidad de la información contenida en la página web en cuanto a los textos, fotografías, logotipos, cupones, productos, servicios e imágenes visualizadas.
            </p>

            <h3>Términos y condiciones de uso</h3>
            <p>
              El acceso a esta página web atribuye la condición de <strong>USUARIO</strong>, quien accede voluntariamente a este sitio web. El acceso y navegación en este sitio web implica aceptar y conocer las advertencias legales, condiciones y términos de uso contenidos en dicho sitio web. El mero acceso no implica el establecimiento de ningún tipo de relación comercial entre el USUARIO y el ANUNCIANTE. La información suministrada en la página web tiene como finalidad facilitar el conocimiento por parte de los usuarios de las actividades y de los servicios que presta el ANUNCIANTE, quien se reserva el derecho de efectuar, en cualquier momento cuantas modificaciones, variaciones, supresión o cancelaciones de los contenidos y en la forma de presentación de los mismos que considere necesaria para la prestación de los servicios. Esta facultad no otorga a los usuarios ningún derecho a percibir indemnización por daños y perjuicios, por lo que se recomienda al USUARIO que lea este Aviso Legal tantas veces como acceda a la web.
            </p>

            <h3>Uso indebido</h3>
            <p>El USUARIO asume la responsabilidad del uso del portal, comprometiéndose a realizar un buen uso de la página web y aportar información veraz y lícita, no permitiéndose conductas que vayan contra la ley, los derechos o intereses de terceros.</p>
            <p>La utilización de ciertos servicios o solicitudes al ANUNCIANTE estarán condicionadas a la previa cumplimentación del correspondiente formulario de recogida de datos. A estos efectos, el USUARIO será responsable de la información falsa o inexacta que pudiera realizar o comunicar y de los perjuicios que cause al ANUNCIANTE y al responsable de la web en su caso.</p>
            <p>El ANUNCIANTE se reservan el derecho a denegar o retirar el acceso a la web a aquellos USUARIOS que incumplen las condiciones y obligaciones de la presente página web.</p>

            <h3>Exclusión de garantías y responsabilidad</h3>
            <p>El ANUNCIANTE no se responsabiliza del mal uso que se realice de los contenidos de la página web, y no se hace responsable en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudiera ocasionar el funcionamiento de la página web y/o sus servicios ni garantizan la utilidad de la página web, ni que el usuario pueda utilizar en todo momento la web o los servicios que se ofrezcan.</p>
            <p>El ANUNCIANTE no puede garantizar la fiabilidad, veracidad, exhaustividad y actualidad de los contenidos y, por tanto queda excluido cualquier tipo de responsabilidad que pudiera derivarse por los daños causados, directa o indirectamente, por la información a la que se acceda por medio de la web.</p>
            <p>No obstante, el ANUNCIANTE declara que han adoptado todas las medidas necesarias, dentro de sus posibilidades y del estado de la tecnología, para garantizar el funcionamiento de la página web y evitar la existencia y transmisión de virus y demás componentes dañinos a los usuarios.</p>

            <h3>Enlaces</h3>
            <p>En el caso de que  en el sitio web se dispusiesen enlaces o hipervínculos hacía otros sitios de Internet, EL ANUNCIANTE no ejercerá ningún tipo de control sobre dichos sitios y contenidos, no asumiendo en ningún caso responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno.</p>

            <h3>Propiedad intelectual e industrial</h3>
            <p>El Copyright, diseño y creación de la página web es titularidad de HERMANOS RODRÍGUEZ FERNÁNDEZ CB. El acceso gratuito no implica otros derechos o licencias para reproducción y/o distribución sin la previa autorización expresa del propietario, por lo que, de conformidad con lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, distribución y comunicación pública de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico sin previa autorización, comprometiéndose el USUARIO en respetar los derechos de Propiedad Intelectual e Industrial.</p>
            <p>Las marcas, logotipos, diseños, contenidos, programas u objetos similares que forman parte de esta página web, están protegidos por leyes de propiedad industrial e intelectual. La reproducción, distribución, manipulación o desensamblaje no autorizado de los códigos fuente, de los algoritmos incorporados o de la base datos, ya sea total o parcial, dará lugar a graves penalizaciones tanto civiles como penales y será objeto de cuantas acciones judiciales correspondan en Derecho.</p>

            <h3>Política de privacidad y protección de datos</h3>
            <p>En virtud de lo dispuesto en el Reglamento General de Protección de Datos (RGPD), Reglamento UE 2016/679, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos, se informa al USUARIO que todos los datos que proporciones serán incorporados a un fichero, creado y mantenido bajo la responsabilidad del ANUNCIANTE con el fin de gestionar el envío de la información que soliciten, facilitar al interesado ofertas de productos y servicios de su interés y mejorar su experiencia de usuario, pudiendo elaborarse, de ser necesario, un perfil comercial, en base a la información facilitada. No se tomarán decisiones automatizadas en base a dicho perfil. Los datos personales proporcionados se conservarán mientras se mantenga la relación mercantil, en su caso, y no se solicite su supresión por el interesado.</p>
            <p>Los contenidos de la página web están dirigidos a personas mayores de edad, el ANUNCIANTE no pretende obtener datos de personas menores de edad, y cuando tenga conocimiento de que existen en su fichero datos de carácter personal de un menor, procederá de inmediato a cancelar dichos datos.</p>

            <h3>Legislación aplicable y jurisdicción</h3>
            <p>La relación con el USUARIO se regirá por la normativa española vigente y cualquier controversia se someterá a los Juzgado y tribunales de la ciudad de A Coruña salvo que la legislación aplicable determine de forma imperativa otro fuero o legislación distinta.</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Imprint
