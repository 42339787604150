import React from 'react'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'

class Pricing extends React.Component {
    render() {
        return (
            <div id="pricing" className="section pricing-section m-tb120">
                <div className="container">
                    <div className="row">
                        {/* Primera columna */}
                        <div className="col-md-6">
                            <div className="price-block">
                                <h3 className="price-header">LUNES A JUEVES</h3>
                                <div className="price-content">
                                    <ul className="price-list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span className="price-amount">
                                                TARIFA TARDE: 160€
                                            </span>
                                            <span className="price-time">
                                                de 16:30 a 20:30 horas
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span className="price-amount">
                                                DÍA COMPLETO: 200€
                                            </span>
                                            <span className="price-time">
                                                de 12:00 a 22:00 horas
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Segunda columna */}
                        <div className="col-md-6">
                            <div className="price-block">
                                <h3 className="price-header">
                                    VIERNES Y VÍSPERAS DE FESTIVOS
                                </h3>
                                <div className="price-content">
                                    <ul className="price-list">
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span className="price-amount">
                                                TARIFA 8 HORAS: 230€
                                            </span>
                                            <span className="price-time">
                                                de 12:00 a 20:00 o de 13:00 a
                                                21:00 horas
                                            </span>
                                        </li>
                                        <li>
                                            <i className="fa fa-check-circle"></i>
                                            <span className="price-amount">
                                                DÍA COMPLETO: 280€
                                            </span>
                                            <span className="price-time">
                                                de 12:00 a 23:30 horas
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        {/* Tercera sección (ancho completo) */}
                        <div className="col-md-12">
                            <div className="price-block">
                                <h3 className="price-header">
                                    SÁBADOS, DOMINGOS Y FESTIVOS
                                </h3>
                                <div className="price-content">
                                    <div className="price-content-flex">
                                        <div className="price-content-main">
                                            <ul className="price-list">
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    <span className="price-amount">
                                                        TARIFA 8 HORAS: 250€
                                                    </span>
                                                    <span className="price-time">
                                                        de 12:00 a 20:00 o de
                                                        13:00 a 21:00 horas
                                                    </span>
                                                </li>
                                                <li>
                                                    <i className="fa fa-check-circle"></i>
                                                    <span className="price-amount">
                                                        DÍA COMPLETO: 300€
                                                    </span>
                                                    <span className="price-time">
                                                        de 12:00 a 00:00 horas
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                        <ul className="price-list">
                                            <li>
                                                <i className="fa fa-glass"></i>
                                                <span className="price-amount">
                                                    FECHAS ESPECIALES
                                                </span>
                                                <span className="price-time">
                                                    Consulta nuestras tarifas
                                                    especiales para Nochebuena,
                                                    Navidad, Fin de Año, Noche
                                                    de Reyes y Reyes
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 text-center mt-4">
                            <HashLink
                                smooth
                                to="/tarifas-y-reservas#reservas"
                                onClick={e =>
                                    this.trackClickOn(
                                        e,
                                        'Prices - Do reservation'
                                    )
                                }
                                className="btn btn-primary"
                            >
                                Haz una reserva
                            </HashLink>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    trackClickOn(event, componentName) {
        window.ga('send', {
            hitType: 'event',
            eventCategory: componentName,
            eventAction: 'click',
        })
    }
}

export default Pricing
