import React from 'react'
import childrenImg from '../../images/nuestras-instalaciones/_A3B1767.jpg'
import multiFunctionalImg from '../../images/nuestras-instalaciones/_A3B1765.jpg'
import chillImg from '../../images/nuestras-instalaciones/_A3B1780.jpg'

class OurFacilities extends React.Component {
  render () {
    return (
      <div id="our-facilities" className="section service-section">
        <div className="container-fluid">
          <div className="col-md-12">
            <span className="section-sub-title">
              Nuestras&nbsp;
              <span>instalaciones</span>
            </span>
          </div>

          <div className="row">
            <div className="col-sm-4">
              <article className="service-single">
                <figure>
                  <img src={ childrenImg } alt="service" />
                </figure>
                <div className="panel panel-default">
                  <h2>ZONA INFANTIL</h2>
                  <div className="panel-body">
                    <ul>
                      <li>Parque de bolas con capacidad para 40 niños con tobogán, cama elástica y tirolina.</li>
                      <li>Parque para bebés</li>
                      <li>Espacio de ocio con mesas y sillas para jugar y colorear.</li>
                      <li>Televisión de 22”</li>
                      <li>Baúl con disfraces</li>
                      <li>30 sillas infantiles</li>
                      <li>12 mesas infantiles</li>
                      <li>Juegos variados</li>
                      <li>Espacio para jugar o bailar (zona con altavoces)</li>
                    </ul>
                  </div>
                </div>
              </article>
            </div>

            <div className="col-sm-4">
              <article className="service-single">
                <figure>
                  <img src={ multiFunctionalImg } alt="service" />
                </figure>
                <div className="panel panel-default">
                  <h2>ZONA MULTIFUNCIONAL</h2>
                  <div className="panel-body">
                    <ul>
                      <li>Espacio diáfano para colocar sillas y mesas</li>
                      <li>Cocina equipada con: vitrocerámica, horno, microondas, nevera y lavavajillas </li>
                      <li>Vajilla, vasos y cubertería con todo lo necesario para 40 personas</li>
                      <li>Menaje infantil para 30 niños</li>
                      <li>Cafetera</li>
                      <li>12 mesas plegables de 183x76x74 cm</li>
                      <li>40 sillas</li>
                      <li>2 tableros y 4 caballetes</li>
                      <li>2 aseos con cambiador y uno adaptado para minusválidos</li>
                    </ul>
                  </div>
                </div>
              </article>
            </div>

            <div className="col-sm-4">
              <article className="service-single">
                <figure>
                  <img src={ chillImg } alt="service" />
                </figure>
                <div className="panel panel-default">
                  <h2>ZONA OCIO</h2>
                  <div className="panel-body">
                    <ul>
                      <li>Wifi y Movistar plus con todo el fútbol</li>
                      <li>Smart TV LG 4 k de 55” y proyector con pantalla de
                          2x2 metros</li>
                      <li>Micrófono y altavoces</li>
                      <li>Sofás</li>
                      <li>Grada multifuncional para ver partidos, películas, dar
                         charlas, cursos, etc. </li>
                      <li>Zona con futbolín y dardos</li>
                      <li>Mesa de ping pong</li>
                      <li>Mesa de póker</li>
                      <li>Juegos de mesa y cartas</li>
                    </ul>
                  </div>
                </div>
              </article>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OurFacilities
