import React from 'react'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import PropTypes from 'prop-types'
import { PHONE_HREF, PHONE_NUMBER } from '../../constants'

class HowDoesItWork extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // message: 'Hello world from class component!'
    }
  }
  render () {
    return (
      <div id="how-does-it-work" className="section service-section">
        { this.props.showTitle &&
          <div className="container-fluid">
            <div className="col-md-12">
              <span className="section-sub-title">
                ¿Cómo&nbsp;
                <span>funciona</span>?
              </span>
            </div>
          </div>
        }

        <div className="container">
          <div className="row">
            <div className="col-md-9 col-md-offset-1 col-sm-12">
              <div className="media">
                <div className="media-left media-top">
                  <i className="fa fa-calendar" aria-hidden="true"></i>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">¿Cómo hacer la reserva?</h4>
                  <p>
                    Ponte en contacto con nosotros mediante el&nbsp;
                    <HashLink smooth to="/tarifas-y-reservas#reservas">
                      formulario de reservas <i className="fa fa-wpforms"></i>
                    </HashLink> o por mensaje de WhatsApp al siguiente número{' '}
                    <a className="whatsapp" href={PHONE_HREF}>{PHONE_NUMBER}&nbsp;<i className="fa fa-whatsapp"></i></a>.
                  </p>
                  <p>
                  Tu reserva se confirmará con el pago del 50% del importe total, mediante transferencia bancaria, ingreso en cuenta o bizum.
                  El otro 50% restante se abonará unos días antes del evento. Una vez reservada la sala, recibirás un correo electrónico con toda la información de la reserva, el contrato de alquiler y las condiciones.
                  </p>
                </div>
              </div>

              <div className="media">
                <div className="media-left media-top">
                  <i className="fa fa-key" aria-hidden="true"></i>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">El día del evento</h4>
                  <p>
                    El día del evento te proporcionaremos un código personal
                    para acceder al local, que quedará&nbsp;
                    <Link to="/condiciones-de-uso">
                      bajo tu responsabilidad
                    </Link>&nbsp;
                    hasta la hora de finalización reservada.
                  </p>
                </div>
              </div>

              <div className="media">
                <div className="media-left media-top">
                  <i className="fa fa-heart" aria-hidden="true"></i>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">A la finalización del evento</h4>
                  <p>
                    A la hora de salida debes dejar el local recogido y ordenado tal
                    y como te fue entregado.
                    El servicio de limpieza corre por cuenta de la empresa.
                    Si no quieres recoger, puedes contratar con nosotros este
                    servicio.
                  </p>
                </div>
              </div>

              <div className="media">
                <div className="media-left media-top">
                  <i className="fa fa-handshake-o" aria-hidden="true"></i>
                </div>
                <div className="media-body">
                  <h4 className="media-heading">Condiciones de uso</h4>
                  <p>
                    Para hacer la reserva debes de estar de acuerdo con las
                    condiciones de uso del local.
                    Puedes leerlas <Link to="/condiciones-de-uso">aquí</Link>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HowDoesItWork.propTypes = {
  showTitle: PropTypes.bool
}

HowDoesItWork.defaultProps = {
  showTitle: true
}

export default HowDoesItWork
