import React from 'react'
import { Link } from 'react-router-dom'
import { PHONE_HREF, PHONE_NUMBER } from '../../constants'
import ContactForm from './ContactForm'

class Reservation extends React.Component {
  render () {
    return (
      <div id="reservas" className="section avaliability-section">
        <div className="container">
          <div className="container section-header">
            <h1 className="title">
              Formulario de reserva
            </h1>
            <p className="sub-title">
              Una vez decidido el día que quieres reservar, solo tienes que
              rellenar el formulario de reserva y nos pondremos en contacto
              contigo.
              <br />
              <br />
              Para cualquier otra cuestión, contactenos mediante el&nbsp;
              <Link to="/contacto">
                formulario de contacto
              </Link> o por mensaje de WhatsApp al siguiente número{' '}
              <a className="whatsapp" href={PHONE_HREF}>{PHONE_NUMBER}&nbsp;<i className="fa fa-whatsapp"></i></a>.
            </p>
          </div>
          <div className="row">
            <div className="col-md-7 col-md-offset-2 col-sm-12">
              <ContactForm isReservationForm={ true } />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Reservation
